<template>
	<div>
		<pui-datatable
			:modelName="modelName"
			:actions="actions"
			:modelColumnDefs="modelColumnDefs"
		></pui-datatable>
		<mlocalidad-modals
			:modelName="modelName"
		></mlocalidad-modals>
	</div>
</template>

<script>
import mlocalidadActions from './MlocalidadActions';
import mlocalidadModals from './MlocalidadModals.vue';

export default {
	name: 'mlocalidad-grid',
	components: {
		'mlocalidad-modals': mlocalidadModals
	},
	data() {
		return {
			modelName: 'mlocalidad',
			actions: mlocalidadActions.gridactions,
			modelColumnDefs: {
				espuerto: {
					render: data => {						
						if (data === '1') {
							return this.returnYes;
						} else {
							return this.returnNo;
						}
					}
				}
			}
		}
	},
	computed: {
		returnYes() {
			return this.$t('yes');
		},

		returnNo() {
			return this.$t('no');
		}
	}
}
</script>
